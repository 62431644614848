import React from 'react';
import Layout from '../components/Layout.js';
import Head from '../components/head';


class NotFound extends React.Component {
  render() {
    return (
      <Layout>
      <Head title="Página no encontrada" />
      <h1>Página no encontrada</h1>
      </Layout>
    );
  }
}

export default NotFound;
